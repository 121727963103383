<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {};
  },
  created() {
    this.$router.push({ path: '/dashboard' });
  },
};
</script>

<style lang="scss" scoped>
</style>
